@import "theme/library/styles";

.manufacturer-logo img {
  @apply p-3.5 tablet:p-0 max-w-[100px] tablet:max-w-[142px] max-h-[100px] tablet:max-h-[142px];
}

popper-content {
  .ngxp__container {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &[aria-hidden="true"] {
      visibility: hidden;
    }
  }
}

// Image Gallery Nav Buttons
.gallery-nav-button {
  @apply absolute text-3xl top-1/2 -translate-y-1/2 hover:text-primary transition-all;
}

.owl-prev.disabled,
.owl-next.disabled {
  @apply hidden pointer-events-none text-primary;
}
