body {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.01em;
}

.text-3xl {
  font-size: 2rem;
}

.leading-none {
  line-height: 0;
}

.hyphens-auto {
  hyphens: auto;
}

.hyphens-none {
  hyphens: none;
}

.big\:hyphens-none {
  @media only screen and (min-width: 1540px) {
    hyphens: none;
  }
}

.svg {
  * {
    transform-box: fill-box;
  }
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ed050d transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;

  @media only screen and (max-width: 768px) {
    width: 4px;
    height: 4px;
  }
}

.scrollbar::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 100vh;
  background: #dcd7ce;
  border: 1px solid transparent;
  background-clip: padding-box;
  @media only screen and (max-width: 767px) {
    width: 4px;
  }
}

.scrollbar.scrollbar--select::-webkit-scrollbar-track {
  margin: 0 0 10px 0;
}

.scrollbar.scrollbar--mobile::-webkit-scrollbar-track {
  @media only screen and (max-width: 900px) {
    margin: 0 40px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 20px;
  }
}

.scrollbar.scrollbar--favourites::-webkit-scrollbar-track {
  margin: 24px 0 64px 0;

  @media (min-width: 1440px) {
    margin: 0 0 64px 0;
  }
}

.scrollbar.scrollbar--cart::-webkit-scrollbar-track {
  margin: 0 0 80px 0;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #ed050d;
  border-radius: 100vh;
  border: 1px solid #ed050d;
}

.after\:table-gradient:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 21.32%, #dcd7ce 145.94%);
  transform: rotate(-180deg);
}

.md\:left-initial {
  @media only screen and (min-width: 768px) {
    left: initial;
  }
}

.md\:left-initial {
  @media only screen and (min-width: 1280px) {
    left: initial;
  }
}

.phone-sml\:left-initial {
  @media only screen and (min-width: 460px) {
    left: initial;
  }
}

.phone-sml\:right-initial {
  @media only screen and (min-width: 460px) {
    right: initial;
  }
}

.md\:right-initial {
  @media only screen and (min-width: 768px) {
    right: initial;
  }
}

.xl\:right-initial {
  @media only screen and (min-width: 1280px) {
    right: initial;
  }
}

.big\:right-initial {
  @media only screen and (min-width: 1440px) {
    right: initial;
  }
}

.md\:top-initial {
  @media only screen and (min-width: 768px) {
    top: initial;
  }
}

.big\:top-initial {
  @media only screen and (min-width: 1440px) {
    top: initial;
  }
}

.mobile-nav\:bottom-inital {
  @media only screen and (min-width: 1000px) {
    bottom: initial;
  }
}

.big\:bottom-initial {
  @media only screen and (min-width: 1440px) {
    bottom: initial;
  }
}

.right-initial {
  @media only screen and (min-width: 1800px) {
    right: initial !important;
  }
}

.left-initial {
  @media only screen and (min-width: 1800px) {
    left: initial !important;
  }
}

.after-height {
  &:after {
    height: 258px;
    height: var(--height);
  }
}

.gap-18px {
  gap: 18px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 30;
}

.group-table {
  &:hover {
    .group-table\:bg-paper,
    .group-table\:before\:bg-paper:before {
      --tw-bg-opacity: 1;
      background-color: rgb(251 250 249 / var(--tw-bg-opacity));
    }
  }
}

.transition-all {
  .js-logo-wrap {
    transition: all;
    transition-duration: 0s;
  }
}

.w-screen {
  width: calc(100vw - 16px);

  @media (hover: none) {
    width: 100vw;
  }
}

.js-header-scan {
  transition: top 0s;
}

.break-words {
  overflow-wrap: break-word;
  word-break: break-word;
}

//ios 15 input date hack
input::-webkit-date-and-time-value {
  text-align: left;
}

.rte,
.accordion,
.text-image,
.rte_ul {
  a {
    font-weight: 700;
    padding-bottom: 2px;
    position: relative;

    &:hover {
      &:after {
        background: #3c3c3b;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 3px;
      background: #aaa69d;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.2s;
    }
  }
}

.accordion {
  p + p {
    margin-top: 20px;
  }

  ul {
    + p {
      margin-top: 20px;
    }
  }

  p {
    br {
      display: block;

      & + strong {
        margin-top: 8px;
      }
    }

    strong {
      display: inline-block;
    }
  }
}

.rte,
.text-image {
  p,
  strong,
  em,
  ul,
  ol,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + p {
      padding-top: 8px;
      margin-top: 0;
    }
  }

  p {
    & + p {
      padding-top: 16px;
    }

    & ul {
      margin-top: 12px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", "sans-serif";
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 48px;
    line-height: 1.25;

    @media only screen and (max-width: 900px) {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 30px;

    @media only screen and (max-width: 900px) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 24px;

    @media only screen and (max-width: 900px) {
      font-size: 18px;
    }
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
}

.rte {
  ul,
  ol {
    margin-top: 16px;
  }

  ul {
    li {
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        margin: 0 8px;
        background-color: #3c3c3b;
      }
    }
  }

  ul {
    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      padding-top: 16px;
      margin-top: 0;
    }
  }
}

.rte,
.article-header-info-wrap {
  ol {
    li {
      position: relative;
      padding-left: 24px;
      &:before {
        position: absolute;
        content: counter(list-item);
        font-size: 9px;
        width: 12px;
        height: 12px;
        font-weight: 700;
        color: white;
        border-radius: 9999px;
        background-color: #3c3c3b;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 4px;
        top: 4px;
      }
    }
  }
}

.rte-ul {
  p {
    & + ul {
      margin-top: 12px;
    }
  }
  li {
    padding-left: 32px;
    margin-bottom: 16px;
    position: relative;

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 9999px;
      background-color: #3c3c3b;
      position: absolute;
      left: 12px;
      top: 8px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.article-header-info-wrap {
  ul {
    li {
      position: relative;
      padding-left: 32px;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        background-color: #3c3c3b;
        position: absolute;
        left: 8px;
        top: 8px;
      }
    }
  }
}

.custom-product-info-list p {
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
}

.custom-image-cover {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.custom-whitespace {
  .js-checkbox-label {
    white-space: nowrap;
  }
}

.custom-text-padding {
  > .pr-8 {
    padding-right: 0;
  }
}

app-breadcrumb {
  width: 100%;
}

//app-horizontal-product-card,
//app-note-list-item {
//  display: block !important;
//}

app-teaser-slider swiper-slide {
  height: auto;
  background: #f1efeb;
}

app-select[name="sPL1DeliveryTime"],
app-select[name="existingContacts"] {
  .js-select-options {
    max-height: 40vh;
    overflow-y: auto;
    @extend .scrollbar;
  }
}

app-select[name="lngContactID"] {
  .js-select-options {
    max-height: 30vh;
    overflow-y: auto;
    @extend .scrollbar;
  }
}

.custom-swiper-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

app-product-list .w-304px {
  min-width: 304px;
}

.custom-hero-text {
  p + p {
    margin-top: 20px;
  }
}

.custom-history-text {
  p + p {
    margin-top: 8px;
  }
}
