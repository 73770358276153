@import 'ngx-popperjs/css/theme-white.css';

popper-content {
  &.compact {
    .ngxp {
      &__container {
        @apply px-5 pt-3 pb-2.5 w-auto;
      }
    }
  }

  .ngxp {
    &__container {
      @apply px-5 pt-6 pb-5 shadow-flyout border border-solid border-primary-light rounded-flyout w-60 md:w-304px lg:w-404px;

      &[data-popper-placement^="right"] {
        > .ngxp__arrow {
          left: -6px;

          &::before {
            @apply border-l border-b border-solid border-primary-light;
          }
        }
      }

      &[data-popper-placement^="left"] {
        > .ngxp__arrow {
          right: -6px;

          &::before {
            @apply border-r border-t border-solid border-primary-light;
          }
        }
      }

      &[data-popper-placement^="top"] {
        > .ngxp__arrow {
          bottom: -6px;

          &::before {
            @apply border-r border-b border-solid border-primary-light;
          }
        }
      }

      &[data-popper-placement^="bottom"] {
        > .ngxp__arrow {
          top: -6px;

          &::before {
            @apply border-l border-t border-solid border-primary-light;
          }
        }
      }
    }
  }
}
