$add-big: "\f101";
$add: "\f102";
$anchor: "\f103";
$arrow-accordion: "\f104";
$burger: "\f105";
$calendar: "\f106";
$cart: "\f107";
$catalog-active: "\f108";
$catalog: "\f109";
$chevron-down: "\f10a";
$chevron-left: "\f10b";
$chevron-right: "\f10c";
$close-small: "\f10d";
$close: "\f10e";
$commission-filled: "\f10f";
$commission: "\f110";
$delete: "\f111";
$download: "\f112";
$drag-drop: "\f113";
$edit: "\f114";
$facebook: "\f115";
$form-error: "\f116";
$grid-active: "\f117";
$grid: "\f118";
$has-notice: "\f119";
$hide: "\f11a";
$info: "\f11b";
$instagram-sml: "\f11c";
$instagram: "\f11d";
$labels: "\f11e";
$like-active: "\f11f";
$like-small-active: "\f120";
$like-small: "\f121";
$like-tile: "\f122";
$like: "\f123";
$linkedin: "\f124";
$list-active: "\f125";
$list: "\f126";
$location: "\f127";
$logout: "\f128";
$mail: "\f129";
$minus: "\f12a";
$notice: "\f12b";
$notification: "\f12c";
$open-link: "\f12d";
$open: "\f12e";
$order: "\f12f";
$person-default: "\f130";
$person: "\f131";
$phone: "\f132";
$plus: "\f133";
$print: "\f134";
$scanner: "\f135";
$search: "\f136";
$settings: "\f137";
$share: "\f138";
$show: "\f139";
$step-done: "\f13a";
$table-filter-active: "\f13b";
$table-filter: "\f13c";
$twitter: "\f13d";
$upload: "\f13e";
$whatsapp: "\f13f";
$youtube: "\f140";

@font-face {
  font-family: "ifont";
  src: url("/assets/fonts/ifont.eot?7566671009d2811cf244c785c00ad2cc?#iefix") format("embedded-opentype"),
  url("/assets/fonts/ifont.woff?7566671009d2811cf244c785c00ad2cc") format("woff"),
  url("/assets/fonts/ifont.ttf?7566671009d2811cf244c785c00ad2cc") format("truetype"),
  url("/assets/fonts/ifont.svg?7566671009d2811cf244c785c00ad2cc#ifont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.ifont {
  font-family: ifont !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  line-height: 1;
}

.ifont--add-big:before {
  content: "\f101";
}
.ifont--add:before {
  content: "\f102";
}
.ifont--anchor:before {
  content: "\f103";
}
.ifont--arrow-accordion:before {
  content: "\f104";
}
.ifont--burger:before {
  content: "\f105";
}
.ifont--calendar:before {
  content: "\f106";
}
.ifont--cart:before {
  content: "\f107";
}
.ifont--catalog-active:before {
  content: "\f108";
}
.ifont--catalog:before {
  content: "\f109";
}
.ifont--chevron-down:before {
  content: "\f10a";
}
.ifont--chevron-left:before {
  content: "\f10b";
}
.ifont--chevron-right:before {
  content: "\f10c";
}
.ifont--close-small:before {
  content: "\f10d";
}
.ifont--close:before {
  content: "\f10e";
}
.ifont--commission-filled:before {
  content: "\f10f";
}
.ifont--commission:before {
  content: "\f110";
}
.ifont--delete:before {
  content: "\f111";
}
.ifont--download:before {
  content: "\f112";
}
.ifont--drag-drop:before {
  content: "\f113";
}
.ifont--edit:before {
  content: "\f114";
}
.ifont--facebook:before {
  content: "\f115";
}
.ifont--form-error:before {
  content: "\f116";
}
.ifont--grid-active:before {
  content: "\f117";
}
.ifont--grid:before {
  content: "\f118";
}
.ifont--has-notice:before {
  content: "\f119";
}
.ifont--hide:before {
  content: "\f11a";
}
.ifont--info:before {
  content: "\f11b";
}
.ifont--instagram-sml:before {
  content: "\f11c";
}
.ifont--instagram:before {
  content: "\f11d";
}
.ifont--labels:before {
  content: "\f11e";
}
.ifont--like-active:before {
  content: "\f11f";
}
.ifont--like-small-active:before {
  content: "\f120";
}
.ifont--like-small:before {
  content: "\f121";
}
.ifont--like-tile:before {
  content: "\f122";
}
.ifont--like:before {
  content: "\f123";
}
.ifont--linkedin:before {
  content: "\f124";
}
.ifont--list-active:before {
  content: "\f125";
}
.ifont--list:before {
  content: "\f126";
}
.ifont--location:before {
  content: "\f127";
}
.ifont--logout:before {
  content: "\f128";
}
.ifont--mail:before {
  content: "\f129";
}
.ifont--minus:before {
  content: "\f12a";
}
.ifont--notice:before {
  content: "\f12b";
}
.ifont--notification:before {
  content: "\f12c";
}
.ifont--open-link:before {
  content: "\f12d";
}
.ifont--open:before {
  content: "\f12e";
}
.ifont--order:before {
  content: "\f12f";
}
.ifont--person-default:before {
  content: "\f130";
}
.ifont--person:before {
  content: "\f131";
}
.ifont--phone:before {
  content: "\f132";
}
.ifont--plus:before {
  content: "\f133";
}
.ifont--print:before {
  content: "\f134";
}
.ifont--scanner:before {
  content: "\f135";
}
.ifont--search:before {
  content: "\f136";
}
.ifont--settings:before {
  content: "\f137";
}
.ifont--share:before {
  content: "\f138";
}
.ifont--show:before {
  content: "\f139";
}
.ifont--step-done:before {
  content: "\f13a";
}
.ifont--table-filter-active:before {
  content: "\f13b";
}
.ifont--table-filter:before {
  content: "\f13c";
}
.ifont--twitter:before {
  content: "\f13d";
}
.ifont--upload:before {
  content: "\f13e";
}
.ifont--whatsapp:before {
  content: "\f13f";
}
.ifont--youtube:before {
  content: "\f140";
}
