app-history {
  .swiper-button-prev:after, :host(.swiper-rtl) .swiper-button-next:after,
  .swiper-button-next:after, :host(.swiper-rtl) .swiper-button-prev:after {
    display: none;
  }

  swiper-slide {
    opacity: 0.3
  }

  swiper-slide.swiper-slide-active {
    opacity: 1
  }

}
