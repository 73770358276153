.app-loading {
  opacity: 0.5;
  pointer-events: none;
}

// Loading-circle
.share-loading {
  border: 2px solid #ed050d;
  border-radius: 50%;
  border-top: 3px solid #ffffff;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
 * Autocomplete
 */
.cdk-overlay-container {
  .mat-autocomplete-panel {
    .mat-option {
      line-height: 1.25 !important;
      height: 54px !important;
      white-space: normal;

      &:hover,
      &.mat-selected {
        @apply bg-primary-bright;
      }
    }

    .mat-focus-indicator {
      &.mat-active {
        @apply bg-primary-bright;
      }

      &.mat-selected {
        > div {
          @apply border-secondary border-b-2;
        }
      }
    }
  }
}

/**
 * Drag & Drop
 */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 7px 31px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 7px 31px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 7px 31px -2px rgba(0, 0, 0, 0.2);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable-favorite-item-list.cdk-drop-list-dragging .draggable-favorite-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
